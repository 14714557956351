// Fonts
@import url('https://fonts.googleapis.com/css2?family=Kanit:wght@100;400;500&display=swap');

// Variables
@import '../variables';

// Font Awesome
@import '~@fortawesome/fontawesome-free/scss/brands';
@import '~@fortawesome/fontawesome-free/scss/regular';
@import '~@fortawesome/fontawesome-free/scss/solid';
@import '~@fortawesome/fontawesome-free/scss/fontawesome';

body, html {
    font-family: 'Kanit', 'Poppins', serif;
}

.menu-link {
    font-size: 1.2em;
}

input[type=file] {
    &:after {
        content: " - Max size 2 Mb";
        color: #dc4131;
    }
}

.archived{
    background-color: #eee;
}
